var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-footer',{staticClass:"pt-5 mt-10",attrs:{"padless":"","color":"grey lighten-3"}},[_c('v-container',{staticClass:"mt-5"},[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMobile),expression:"!isMobile"}],staticClass:"text-md-left text-center",attrs:{"cols":"12","lg":"4"}},[_c('h4',[_vm._v(_vm._s(_vm.$t("components.appFooter.template.contact")))]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col"},[_c('ul',{staticClass:"ib-footer__nav"},[_c('li',{staticClass:"mb-5"},[_c('h4',[_vm._v(_vm._s(_vm.$t("components.appFooter.template.atending")))]),_c('a',{staticClass:"purple--text text--darken-3",attrs:{"href":"mailto:sac@ezzebank.com.br","target":"_blank"}},[_vm._v("sac@ezzebank.com.br")])]),_c('li',{staticClass:"mb-5"},[_c('h4',[_vm._v(_vm._s(_vm.$t("components.appFooter.template.ombudsman")))]),_c('a',{staticClass:"ib-link",attrs:{"href":"mailto:ouvidoria@ezzebank.com.br","target":"_blank"}},[_vm._v("ouvidoria@ezzebank.com.br")])])])])])]),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMobile),expression:"!isMobile"}],staticClass:"text-md-left text-center",attrs:{"cols":"12","lg":"4"}},[_c('ul',{staticClass:"ib-footer__nav"},[_c('li',{staticClass:"mb-5"},[_c('a',{staticClass:"ib-link",attrs:{"href":((url) => {
                  return _vm.$i18n.locale === 'pt'
                    ? url
                    : url.replace(
                        'https://ezzepay.com.br/',
                        'https://ezzepay-com-br.translate.goog/'
                      ) +
                        '?_x_tr_sl=pt&_x_tr_hl=pt-BR&_x_tr_pto=wapp&_x_tr_tl=' +
                        _vm.$i18n.locale;
                })('https://ezzepay.com.br/pay/politica-de-privacidade/'),"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("components.appFooter.template.privacy")))])]),_c('li',{staticClass:"mb-5"},[_c('a',{staticClass:"ib-link",attrs:{"href":((url) => {
                  return _vm.$i18n.locale === 'pt'
                    ? url
                    : url.replace(
                        'https://ezzepay.com.br/',
                        'https://ezzepay-com-br.translate.goog/'
                      ) +
                        '?_x_tr_sl=pt&_x_tr_hl=pt-BR&_x_tr_pto=wapp&_x_tr_tl=' +
                        _vm.$i18n.locale;
                })('https://ezzepay.com.br/pay/termos-e-condicoes-de-uso/'),"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("components.appFooter.template.terms")))])]),_c('li',{staticClass:"mb-5"},[_c('a',{staticClass:"ib-link",attrs:{"href":"https://dev.ezzebank.com","target":"_blank"}},[_vm._v(_vm._s(_vm.$t("components.appFooter.template.devs")))])])])]),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMobile),expression:"!isMobile"}],attrs:{"cols":"12","lg":"4"}},[_c('v-row',[_c('v-col',{staticClass:"text-md-left text-center",attrs:{"cols":"12","lg":"6"}},[_c('h4',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$t("components.appFooter.template.social"))+" ")]),_c('v-btn',{attrs:{"icon":"","color":"purple darken-3","href":"https://www.facebook.com/ezzebank","target":"_blank"}},[_c('v-icon',[_vm._v("mdi mdi-facebook")])],1),_c('v-btn',{attrs:{"icon":"","color":"purple darken-3","href":"https://www.instagran.com/ezzebank","target":"_blank"}},[_c('v-icon',[_vm._v("mdi mdi-instagram")])],1),_c('v-btn',{attrs:{"icon":"","color":"purple darken-3","href":"https://twitter.com/ezzebank","target":"_blank"}},[_c('v-icon',[_vm._v("mdi mdi-twitter")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"4"}},[_c('LocaleSwitcher')],1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMobile),expression:"!isMobile"}],staticClass:"text-center",attrs:{"cols":"12"}},[_c('small',{staticClass:"fw-bolder"},[_vm._v(" "+_vm._s(new Date().getFullYear())+" © EzzeBank - "+_vm._s(_vm.$t("components.appFooter.template.rights")))])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }